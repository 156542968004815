:root{
  background:#0d0d0d;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.App {
  text-align: center;
  height:100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-body {
  height: 100vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
}
.bg{
  position: absolute;
  width: 100%;
  z-index: -1;
  background:black ;
}

.words{
  z-index: 1;
  top:0vh;
  width:95%;
  position: absolute;
  display: flex;
  margin:auto;
  justify-content: center;
}

.logo{
  left: 2%;
    top: 8%;
    position: absolute;
    height: 150px;
}

.wizard{
  position: absolute;
  z-index: 1;
  left: 0;
    display: block;
    height: 738px;
    top: 25vh;
}

@media (min-width: 1000px) {
  .App-body{
    overflow-y: hidden;
  }
}

.menu{
  position: absolute;
  display: flex;
  justify-content: center;
  margin:auto;
  z-index: 2;
}

.menu a{
  color:#fff;
  text-decoration: underline 0.1em rgba(255, 255, 255, 0);
  transition: all 0.3s;
  text-transform: uppercase;
  margin:15px 15px 0 0;
  
}
.menu a:hover {
  color: #eee;
  text-decoration-color: rgba(255, 255, 255, 1);
}
.active{
  background: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size:3.5em;
}
.contact-link{
  right: 4%;
  position: absolute;
  margin:15px 15px 0 0;
  cursor: pointer;
}
.contact-link svg{
  cursor: pointer;
}
.email-icon{
  width:50px;
}
@media(max-width:1000px){
  .email-icon{
    width:30px;
  }
  .menu .active{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    padding-top:45px;
    pointer-events: none;
  }
}
.home{
  font-size:0em;
}

.menu .page-size{
  position: absolute;
  right:0;
  font-size:.7em;
}
.menu .page-size p{
  text-transform: none;
  margin:auto;
}
.kbs{
  font-size: .7em;
  text-transform: none;
  margin-left:5px;
}

.App-link {
  color: #61dafb;
}
.about-orb {
  animation: orb-movement infinite 25s linear, orb-rotate infinite 15s linear;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  animation:none;
  }
  .orb-link{
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
  }

  .orb1{
    animation:orb-movement infinite 25s linear, orb-rotate infinite 15s linear;
  }

  .orb2{
    animation:orb-movement2 infinite 20s linear, orb-rotate infinite 20s linear;
  }

  

@keyframes orb-movement {
  0% {
      margin:0px 0px 0px 0px;
      filter: drop-shadow(2px 4px 6px rgb(12, 255, 223));
  }
  25% {
      margin:80px 0px 0px 90px;
  }
  50% {
      margin:0px 55px 0px 70px;
      filter: drop-shadow(2px 4px 6px rgb(212, 0, 255));
  }
  75% {
      margin:90px 10px 0px 80px;
  }
  100% {
      margin:0px 0px 0px 0px;
      filter: drop-shadow(2px 4px 6px rgb(12, 255, 223)); 
  }
}

@keyframes orb-movement2 {
  0% {
      margin:0px 0px 0px 0px;
      filter: drop-shadow(2px 4px 6px rgb(12, 28, 255));
  }
  25% {
    margin:50px 0px 30px 0px;
  }
  50% {
    margin:40px 20px 0px 50px;
    filter: drop-shadow(2px 4px 6px rgb(235, 12, 255));
  }
  75% {
      margin:80px 0px 0px 90px;
  }
  100% {
      margin:0px 0px 0px 0px;
      filter: drop-shadow(2px 4px 6px rgb(12, 28, 255)); 
  }
}

@keyframes orb-rotate {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
@keyframes no-animation{
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(0deg);
  }
}

.whale{
  width: 100%;
  top: 0;
}

.atmosphere{
  height:100vh;
  width:100vw;
}
.phone-svg{
  width: 100%;
  height:100vh;
}
.night-bg{
  width:100vw;
}

.whale #whale-path1{
  animation: filter-move infinite 7s linear;
}
#whale-mountain{
  filter: drop-shadow(0 0 1rem rgb(241,58,44)) drop-shadow(5px 10px 1.25rem rgb(241,58,44));

}

#whale-path2, #whale-path5, .whale #whale-path4{
  animation: filter-move infinite 7s linear;
}


@keyframes filter-move {
  0% {
    filter: drop-shadow(0 0 0.44rem rgb(241,58,44)) drop-shadow(0px 0px .4rem rgb(241,58,44));
  }
  20% {
    filter: drop-shadow(0 0 1.55rem rgb(241,58,44)) drop-shadow(8px 10px 1.25rem rgb(241,58,44));
  }
  40% {
    filter: drop-shadow(0 0 1.83rem rgb(241,58,44)) drop-shadow(30px 14px 1.55rem rgb(241,58,44));
  }
  60% {
    filter: drop-shadow(0 0 1rem rgb(241,58,44)) drop-shadow(5px 10px 1.25rem rgb(241,58,44));
  }
  100% {
    filter: drop-shadow(0 0 0.44rem rgb(241,58,44)) drop-shadow(0px 0px .4rem rgb(241,58,44));
  }
}

@media (max-width: 800px) {
  .logo{
    top:5px;
    height:auto;
    width:16vw;
    left:15px;
  }
  .words{
    width:75vh;
  }

  .wizard{
    height: 60vh;
    top: 22vh;
  }
  .atmosphere{
    width:150vw;
    height:auto;
  }
  .next{
    filter:brightness(31.5)
  }

  .contact-link{
    width:25px;  }

  @keyframes orb-movement {
    0% {
        margin:0px 0px 0px 0px;
        filter: drop-shadow(2px 4px 6px rgb(12, 255, 223));
    }
    25% {
        margin:40px 0px 0px 50px;
    }
    50% {
        margin:0px 15px 0px 40px;
        filter: drop-shadow(2px 4px 6px rgb(212, 0, 255));
    }
    75% {
        margin:50px 10px 0px 50px;
    }
    100% {
        margin:0px 0px 0px 0px;
        filter: drop-shadow(2px 4px 6px rgb(12, 255, 223)); 
    }
  }
  
  @keyframes orb-movement2 {
    0% {
        margin:0px 0px 0px 0px;
        filter: drop-shadow(2px 4px 6px rgb(12, 28, 255));
    }
    25% {
      margin:50px 0px 30px 0px;
    }
    50% {
      margin:40px 20px 0px 50px;
      filter: drop-shadow(2px 4px 6px rgb(235, 12, 255));
    }
    75% {
        margin:80px 0px 0px 90px;
    }
    100% {
        margin:0px 0px 0px 0px;
        filter: drop-shadow(2px 4px 6px rgb(12, 28, 255)); 
    }
  }

}

.word{
    font-size:2.2em;
    margin: 15px 0 15px 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}
.about h1{
    font-weight: 400;
    margin: 0;
    z-index: 3;
}
.about{
    text-transform: uppercase;
}
.about span{
    background: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
    background-clip:text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color:transparent;
    font-size:2em;
}

.about-image{
    width:100%;
    height:auto;
}
.about-image-container{
    display: flex;
    justify-content: center;
    z-index: 2;
}
.like-space{
    position:absolute;
    bottom:10vh;
    right:5vh;
    font-size:0.7em;
}

.about-me {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:3;
    width: 100%;
    flex-direction: column;
    position: absolute;
    width:85%;
    height: 100%;
    padding:0;
    margin:0;
    top:5vh;
    list-style-type:none;
    filter: drop-shadow(0px 0px 0.25rem #000);
}
.about-me li{
    padding-bottom:10px;
    background: #0000004f;
}
.about-me li:after{
    content: " ]"
}
.about-me li:before{
    content: "[ "
}
.about-me li:nth-child(1){
    color:#2AF598;
}
.about-me li:nth-child(2){
    color:#2af5d3;
}
.about-me li:nth-child(3){
    color:#2af5f5
}
.about-me li:nth-child(4){
    color:#2accf5
}
.starfield-about{
    height:100%;
    position:absolute;
    z-index: 0;
    pointer-events: none;
    width:100%;
}

.close-icon-about{
    position:absolute;
    top: 2%;
    right:6%;
    width:80px;
    cursor:pointer;
    transition: all 0.5s;
    z-index:9;
    filter: drop-shadow(0px 0px 0.75rem rgb(255, 255, 255));
}

@media (max-width: 800px) {
    .about-me li{
        padding-bottom: 15px;
    }

}
@media (min-width: 1500px) {
    .about-me{
        top:15vh;
    }

}
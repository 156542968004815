// $base-color: #c6538c;
// $desktop-space: calc(75% - (23% +1%));


.p-card-container{
    width:100%;
    height:100vh;
    position:absolute;
}

.p-card{
    position: absolute;
    width:20%;
    height:300px;
    background-color: #fff;
    opacity: 0.8;
    border-radius: 25px;
    filter: drop-shadow(0px 0px 0.55rem rgb(255, 255, 255));
    cursor:pointer;
    transition: all 0.6s;
    z-index:1;
}

.p-card:hover{
    
}

.starfield{
    width:100vw;
    height:70vh;
    position:absolute;
    z-index: 0;
    pointer-events: none;
}

.p-header{
    width:100%;
    object-fit: cover;
    display:block;
    object-position: top;
}

.content-bg{
    height:100vh;
    display:block;
    cursor:default;
    pointer-events: none;
}

.close-icon{
    position:absolute;
    top: 2%;
    right:6%;
    width:80px;
    cursor:pointer;
    transition: all 0.5s;
    z-index: 9997;
    pointer-events: all;

}
.main-close{
    position:absolute;
    top: 2%;
    right:2%;
    width:60px;
    cursor:pointer;
    transition: all 0.5s;
    z-index: 9999999;
    pointer-events: all;
    filter:drop-shadow(0px 0px .55rem #000);

}
.next{
    position:absolute;
    top: 35vh;
    right:5%;
    width:80px;
    cursor:pointer;
    transition: all linear 0.2s;
}
.prev{
    left:5%;
}
.next:hover{
    right:4%;
}
.prev:hover{
    left:4%;
}
.p-copy{
    position:absolute;
    width:100%;
}
.copy{
    z-index: 1;
    text-align: left;
    font-size:0.8em;

}
.tag{
    margin:0 0 8px 0;
}
.tag p{
    display: inline-block;
    padding:5px 10px 5px 10px;
    margin:0 0 0 5px;
    background-color: #FF3CAC;
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
    border-radius: 5px;
}
// .fa-vuejs{
//     color:#fd7e14;
// }
// .fa-react{
//     color:#61dafb;
// }


/* .p-card0{
    animation: gravity infinite 8s linear;
}

.p-card1{
    animation: gravity-2 infinite 8s linear;
}

.p-card2{
    animation: gravity infinite 10s reverse;
} */

// .inner-p-card{
// }

// .current-container{
//     width: 96%;
//     left:2%;
//     height: 71vh;
//     position: absolute;
//     background:rgba(129, 129, 129, 0.7);
//     z-index: 3;
// }

.close-box{
    width:100%;
    height:100vh;
    z-index: 2;
    position: absolute;
    background:transparent;
}

.p-copy h1{
  z-index:4;
  margin:0;
  font-weight:500;
  line-height: 28px;
  padding: 15px 10px 15px 10px;
}

.p-title{
    background: linear-gradient(0deg, #9100ff 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.gallery-img{
    width:40%;
    object-fit: contain;
    position: absolute;
    left: 50%;
    filter:drop-shadow(4px 4px .55rem #000);
    cursor:zoom-in ;
}

.copy{
    flex-direction: column;
    cursor:auto;
    width:40vw;
}
.copy p {
    width:90%;
    margin:0 0 30px 0;
    cursor:auto;
}
.p-button{
    padding:10px;
    background-color: #08AEEA;
    background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
    color:#fff;
    width: 50%;
    text-decoration: none;
    text-align: CENTER;
    border-radius: 15px;
    transition: all 0.3s;
    filter: drop-shadow(0px 0px 0.45rem rgb(51, 242, 248));
}
.p-button:hover{
    padding:10px;
    color:#fff;
    filter: drop-shadow(0px 0px 0.85rem rgb(51, 242, 248));
    
}
.sunrise-mobile{
    height:130vh;
}
.sub-gallery{
    height: auto;
    left: 50vw;
    top: 70vh;
    display: flex;
    position: absolute;
}
.sub-gallery img{
    width:15vw;
    padding:0.5%;
    cursor: pointer;
    z-index: 99999999999;
}

@keyframes gravity {
    0% {
        margin:20px 0px 0px 20px;      
    }
    25% {
        margin:30px 0px 0px 25px;        
    }
    50% {
        margin:35px 0px 0px 30px; 
    }
    75% {
        margin:30px 0px 0px 25px; 
    }
    100% {
        margin:20px 0px 0px 20px; 
    }
  }

  @keyframes gravity-2 {
    0% {
        margin:20px 0px 0px 20px;      
    }
    25% {
        margin:10px 0px 0px 18px;        
    }
    50% {
        margin:5px 0px 0px 20px; 
    }
    75% {
        margin:10px 0px 0px 22px; 
    }
    100% {
        margin:20px 0px 0px 20px; 
    }
  }
  path #erNFyacJjAe5{
      opacity:0.8;
  }

  @media (max-width: 800px) {
    .copy{
        width:100%;
        text-align: center;
    }
    .next{
        top: 115vh;
        padding-bottom:30px;
    }
    .p-card{
        animation: none;
    }
    .close-icon{
        top: 5px;
        right:5px;
        width:50px;
    
    }
    .copy p{
        font-size: 20px;
        margin:0 auto 12px auto;
        width:90%;
    }
    .p-button{
        left:25%;
        margin:auto;
        margin-top:15px;
        font-size: 1.5em;
    }
    .p-title{
        font-size: 2.5em;
    }
    .tag p{
        font-size: 1.5em
    }
    .gallery-img{
        left:10%;
        width:80%;
        top:7vh;
    }
    .sub-gallery img{
        width:24vw;
    }
    .sub-gallery{
        left: 0;
        display: flex;
        right: 0;
        justify-content: center;
        top:31vh;
    }
  }
  

